<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Rutas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10">
                    <h5>Rutas</h5>
                  </div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#modal-csRutas"
                        @click="abrirModal('Crear', null)"
                        v-if="$store.getters.can('cs.rutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#cs_rutas_export"
                        v-if="$store.getters.can('cs.rutas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        ID Ruta
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_ruta"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th class="text-center">Cantidad Kilometros</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in rutas.data" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.cant_kilometros }}</td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-csRutas"
                            v-if="$store.getters.can('cs.rutas.edit')"
                            @click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('cs.rutas.delete')"
                            @click="destroy(item)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="modal fade" id="modal-csRutas">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="nombre">Nombre</label>
              <input
                v-model="form.nombre"
                type="text"
                class="form-control form-control-sm col-md-12"
                style="font-size: 13px"
                :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
              />
              <div class="error" v-if="!$v.form.nombre.required">
                Ingrese el Nombre
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="cant_kilometros">Cantidad Kilometraje</label>
              <input
                v-model="form.cant_kilometros"
                type="number"
                class="form-control form-control-sm col-md-12"
                style="font-size: 13px"
                :class="
                  $v.form.cant_kilometros.$invalid ? 'is-invalid' : 'is-valid'
                "
              />
              <div class="error" v-if="!$v.form.cant_kilometros.required">
                Ingrese la Cantidad Kilometraje
              </div>
            </div>
            <div class="form-group col-md-12">
              <label>Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="form.estado"
                :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.form.estado.required">
                Ingrese el Estado
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="!$v.form.$invalid"
            >
              Guardar
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <CsRutaExport ref="CsRutaExport" />
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";
import CsRutaExport from "./CsRutaExport";

export default {
  name: "CsRutasIndex",
  components: {
    Loading,
    pagination,
    CsRutaExport,
  },
  data() {
    return {
      cargando: false,
      rutas: {},
      filtros: {
        id_ruta: null,
        nombre: null,
        estado: null,
      },
      form: {
        nombre: null,
        cant_kilometros: null,
        estado: null,
      },
      listasForms: {
        estados: [],
      },
      modal: {
        title: "",
        accion: "",
      },
    };
  },
  validations() {
    return {
      form: {
        nombre: {
          required,
        },
        cant_kilometros: {
          required,
        },
        estado: {
          required,
        },
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/cs/rutas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.rutas = response.data;
          this.cargando = false;
        });
    },

    abrirModal(accion, idRuta) {
      this.getEstados();
      this.modal.accion = accion;
      this.modal.title = accion + " Ruta";
      if (accion == "Editar") {
        this.show(idRuta);
      } else {
        this.form = {
          nombre: null,
          cant_kilometros: null,
          estado: null,
        };
      }
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios
          .put("/api/cs/rutas", this.form)
          .then((response) => {
            this.$refs.closeModal.click();
            this.form = {};
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se actualizó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vualeva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idRuta) {
      axios.get("/api/cs/rutas/show/" + idRuta).then((response) => {
        this.form = response.data;
      });
    },

    destroy(item) {
      this.$swal({
        title: "Esta seguro de eliminar la ruta: " + item.nombre + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/cs/rutas/" + item.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó la ruta exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
